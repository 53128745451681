:root {
  //--primary: #FCC60B;
  //--primary-hover: #D9AC09;
  //--primary-light: #FFF0BC;
  //--primary-dark: #E6B810;
  //--secondary: #3F48A7;
  //--secondary-hover: #313882;
  //--default: #F1F1F2;
  //--default-hover: #CFCFCF;
  //--white: #ffffff;
  //--black: #000000;

  --primary: #F39120;
  --primary-hover: #CB4927;
  --primary-light: #FCD6BD;
  --primary-dark: #EA6E24;
  --secondary: #1E3160;
  --secondary-hover: #313882;
  --default: #F1F1F2;
  --default-hover: #CFCFCF;
  --white: #ffffff;
  --black: #121425;

  --blue50: #FBFCFF;
  --blue100: #F7F9FE;
  --blue200: #F1F5FE;
  --blue300: #E7EEFD;
  --blue400: #D6E2FB;
  --blue500: #ADC6F7;
  --blue600: #8FB0F4;
  --blue700: #6694F0;
  --blue800: #3370EB;
  --blue900: #1445A8;

  --gray50: #FAFAFA;
  --gray100: #F8F9FA;
  --gray200: #E9ECEF;
  --gray300: #DEE2E6;
  --gray400: #CED4DA;
  --gray500: #ADB5BD;
  --gray600: #6C757D;
  --gray700: #495057;
  --gray800: #343A40;
  //--gray900: #212529;
  --gray900: #1E3160;

  --red50: #FDFAFA;
  --red100: #FBF6F6;
  --red200: #F8EFEF;
  --red300: #F3E3E3;
  --red400: #EBD0D0;
  --red500: #D7A1A1;
  --red600: #C97E7E;
  --red700: #B54F4F;
  --red800: #9C1414;
  --red900: #7D1010;

  --green50: #F6FBF7;
  --green100: #F5FAF5;
  --green200: #EEF7EE;
  --green300: #E2F1E2;
  --green400: #CEE7CE;
  --green500: #9DCF9D;
  --green600: #79BD79;
  --green700: #48A548;
  --green800: #0B870B;
  --green900: #097309;

  --attention50: #FFFEFA;
  --attention100: #FFFCF5;
  --attention200: #FFFBED;
  --attention300: #FEF7E0;
  --attention400: #FEF2CC;
  --attention500: #FCE699;
  --attention600: #FBDC73;
  --attention700: #F18E43;
  --attention800: #ED6804;
  --attention900: #B94300;

  --purple50: #F9F6FB;
  --purple100: #F9F5FA;
  --purple200: #F4EDF6;
  --purple300: #ECE0F0;
  --purple400: #DFCCE6;
  --purple500: #BF99CC;
  --purple600: #A773B9;
  --purple700: #8740A0;
  --purple800: #7C3BBD;
  --purple900: #4C0066;

  --other-red: #E05252;
  --other-red-light: #FBE9E9;
  --other-orange: #D08310;
  --other-orange-light: #FFF2D9;
  --other-green: #33AE61;
  --other-green-light: #EBF9F0;
  --other-blue: #3072F2;
  --other-blue-light: #E9F0FD;
  --other-purple: #6230F2;
  --other-purple-light: #EEE9FD;
  --other-pink: #F230EA;
  --other-pink-light: #FDE9FD;
}
