.badge {
    height: 24px;
    width: 100px;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    padding: 0 0.75rem;
    border-radius: 0.25rem;
    white-space: normal !important;
}

.badge i {
    margin-right: 0.25rem;
}

.badge.badge-auto {
    width: auto !important;
}

.badge.badge-gray {
    color: var(--gray800);
    background: var(--gray200);
}

.badge.badge-red {
    color: var(--red800);
    background: var(--red200);
}

.badge.badge-purple {
    color: var(--purple800);
    background: var(--purple200);
}