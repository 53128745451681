.date-range-picker-container, .select-container {
  .input {
    display: flex;
    justify-content: space-between;
    height: 36px;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    text-overflow: ellipsis;
    padding: 8px 20px 8px 12px;
    border-radius: 4px;
    border: 1px solid var(--gray400);
    color: var(--gray900);
    gap: 8px;
    margin-bottom: 4px;
    cursor: pointer;

    .placeholder {
      color: var(--gray500)
    }

    &.opened {
      border: 1px solid var(--primary);
      box-shadow: 0 0 0 3px var(--primary-light);
    }
  }

  .input:focus-visible {
    outline: none;
  }

  .input:hover {
    background-color: var(--gray100);
    border: 1px solid var(--gray500)
  }
}
