.asic-form {
  font-size: 12px;
  font-weight: 600;
  color: var(--gray600);
}

.modal {
  z-index: 1055!important;
  //z-index: 1060!important;
}

.success-marker {
  color: green;
}

