.quick-navigation {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 9999;
    padding-right: 1rem;
  }

  /* navigation is hidden when browser is too small to avoid overlapping content. */
  @media(max-width: 1839px) {
    .quick-navigation {
      display: none !important;
    }
  }

  .quick-navigation .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 100px;
    color: var(--gray800) !important;
    font-size: 12px;
    font-weight: 500 !important;
    background: var(--white);
    box-shadow: rgb(224, 224, 224) 0px 0px 22px 0px;
    border: 1px solid var(--gray200);
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;
  }

  .quick-navigation .item:hover {
    background: var(--gray100);
  }

  .hide-empty:empty {
    display: none;
  }

  .superuser {
    background-color: var(--purple500) !important;
  }

  .superuser-dark {
    background-color: var(--purple800) !important;
  }
