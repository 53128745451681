$icon-path: '../assets/svg/';
$icon-size-extra-small-tiny: 8px;
$icon-size-tiny: 10px;
$icon-size-extra-large-tiny: 12px;
$icon-size-extra-small: 14px;
$icon-size-small: 16px;
$icon-size-default: $icon-size-small;
$icon-size-medium: 20px;
$icon-size-large: 24px;
$icon-size-xlarge: 36px;
$icon-size-xxlarge: 48px;

$icons: (
  'help-circle': 'help-circle-icon.svg',
  'log-out': 'log-out-icon.svg',
  'my-account': 'my-account-icon.svg',
  'select-icon': 'select-icon.svg',
  'accounts': 'accounts-icon.svg',
  'companies': 'companies-icon.svg',
  'edit-company': 'edit-company.svg',
  'individuals': 'individuals-icon.svg',
  'documents': 'documents-icon.svg',
  'form': 'form-icon.svg',
  'generate-documents': 'generate-documents.svg',
  'bolt-logo': 'bolt-logo-icon.svg',
  'collapse': 'collapse-icon.svg',
  'expand': 'expand-icon.svg',
  'close': 'close-icon.svg',
  'circle-info': 'circle-info-icon.svg',
  'circle-check': 'circle-check-icon.svg',
  'circle-check-20': 'circle-check-20.svg',
  'home': 'home.svg',
  'edit': 'edit.svg',
  'plus': 'plus.svg',
  'filter': 'filter.svg',
  'table': 'table.svg',
  'eye': 'eye.svg',
  'eye-off': 'eye-off.svg',
  'three-dots': 'three-dots.svg',
  'radio-selected': 'radio-selected.svg',
  'radio-unselected': 'radio-unselected.svg',
  'download-file': 'download-file.svg',
  'alert-triangle': 'alert-triangle.svg',
  'calendar': 'calendar.svg',
  'notes': 'notes.svg',
  'phone': 'phone.svg',
  'debt': 'debt.svg',
  'form-pending': 'form-pending.svg',
  'refresh': 'refresh.svg',
  'link': 'link.svg',
  'unlink': 'unlink.svg',
  'external-link': 'external-link.svg',
  'arrow-left': 'arrow-left.svg',
  'arrow-right': 'arrow-right.svg',
  'close-small': 'close-small.svg',
  'checkmark': 'checkmark.svg',
  'clock': 'clock.svg',
  'lock': 'lock.svg',
  'trash': 'trash.svg',
  'file': 'file.svg',
  'upload-file': 'upload-file.svg',
  'form-edit': 'form-edit.svg',
  'chevron-down': 'chevron-down.svg',
  'cancel-circle': 'cancel-circle.svg',
  'circle-cancel-20': 'circle-cancel-20.svg',
  'done-circle': 'done-circle.svg',
  'info-circle': 'info-circle.svg',
  'shares': 'shares-icon.svg',
  'trusts': 'trusts-icon.svg',
  'drag-and-drop': 'drag-and-drop.svg',
  'group-by': 'group-by-icon.svg',
  'minus': 'minus.svg',
  'wrench': 'wrench.svg',
  'inbox': 'inbox.svg',
  'archive': 'archive.svg',
  'bell': 'bell.svg',
  'attachment': 'attachment.svg',
  'ignore': 'ignore-icon.svg',
  'settings': 'settings.svg',
  'tags': 'tags.svg',
  'tag': 'tag.svg',
  'bulk-changes': 'bulk-changes.svg',
  'organisation': 'organisation.svg',
  'key': 'key.svg',
  'mail': 'mail.svg',
  'message': 'message.svg',
  'asic-black-white': 'asic-black-white.svg',
  'annual-statement-icon': 'annual-statement-icon.svg',
  'send': 'send.svg',
  'outgoing-emails': 'outgoing-emails.svg',
  'sent-mail': 'sent-mail.svg',
  'timer': 'timer.svg',
  'pause-circle': 'pause-circle.svg',
);

.icon {
  background-color: black;
  display: inline-block;
  vertical-align: middle;

  @each $name, $icon in $icons {
    &.ic-#{$name} {
      mask: url($icon-path + $icon);
      -webkit-mask-position: center;
      mask-position: center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: contain;
      mask-size: contain;
      height: $icon-size-default;
      width: $icon-size-default;
    }
  }

  &.ic-tiny-xs {
    height: $icon-size-extra-small-tiny !important;
    width: $icon-size-extra-small-tiny !important;
    min-width: $icon-size-extra-small-tiny !important;
  }

  &.ic-tiny {
    height: $icon-size-tiny !important;
    width: $icon-size-tiny !important;
    min-width: $icon-size-tiny !important;
  }

  &.ic-tiny-lg {
    height: $icon-size-extra-large-tiny !important;
    width: $icon-size-extra-large-tiny !important;
    min-width: $icon-size-extra-large-tiny !important;
  }

  &.ic-xs {
    height: $icon-size-extra-small !important;
    width: $icon-size-extra-small !important;
    min-width: $icon-size-extra-small !important;
  }

  &.ic-sm {
    height: $icon-size-small !important;
    width: $icon-size-small !important;
    min-width: $icon-size-small !important;
  }

  &.ic-md {
    height: $icon-size-medium !important;
    width: $icon-size-medium !important;
    min-width: $icon-size-medium !important;
  }
  &.ic-lg {
    height: $icon-size-large !important;
    width: $icon-size-large !important;
    min-width: $icon-size-large !important;
  }
  &.ic-xlg {
    height: $icon-size-xlarge !important;
    width: $icon-size-xlarge !important;
    min-width: $icon-size-xlarge !important;
  }
  &.ic-xxlg {
    height: $icon-size-xxlarge !important;
    width: $icon-size-xxlarge !important;
    min-width: $icon-size-xxlarge !important;
  }
}
