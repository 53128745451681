.mh-50 {
  min-height: 50px;
}
.mh-200 {
  height: 100%;
  min-height: 200px;
}
.width-360{
  width: 360px;
}

.mh-400 {
  height: 100%;
  min-height: 400px;
}

.mw-400 {
  width: 100%;
  max-width: 400px;
}

.mrgl-05r {
  margin-left: 0.5rem;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: grab;
}

.cursor-move {
  cursor: move;
}

.cursor-text {
  cursor: text !important;
}

.no-select {
  user-select: none;
}

.gap-4 {
  gap: 4px;
}

.gap-6 {
  gap: 6px;
}

.gap-8 {
  gap: 8px;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.gap-24 {
  gap: 24px;
}

.gap-32 {
  gap: 32px;
}

.hidden {
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
  opacity: 0;
}

.flex-text-ellipsis {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.display-none {
  display: none;
}
