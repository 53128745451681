table {
  color: var(--gray600) !important;
}

.table th {
  font-size: 12px;
  font-weight: 600;
  color: var(--gray400);
  padding: 1rem 0.75rem;
  text-transform: uppercase;
  border-top: none;
  border-bottom: none !important;
}

.table td {
  padding: 0.75rem 0.75rem;
  border-top: 1px dashed var(--gray200);
}

.table tr>th:first-child,
.table tr>td:first-child {
  padding-left: 0 !important;
}

.table tr>th:last-child,
.table tr>td:last-child {
  padding-right: 0 !important;
}

.table-middle th,
.table-middle td {
  vertical-align: middle;
}

.table-top th,
.table-top td {
  vertical-align: top;
}

.table-sm th,
.table-sm td {
  padding: 0.5rem;
}

.table-noborder th,
.table-noborder td {
  border: none !important;
}

.table-hover tbody tr:hover {
  cursor: pointer;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), #FFFFFF;
}