.form-control {
  color: var(--gray900) !important;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 1.6;
  height: 36px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0.25rem;
  background-color: var(--gray100);
  border-color: var(--gray100);
}

.form-control:focus {
  color: var(--gray900) !important;
  background-color: var(--gray200) !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: var(--gray200) !important;
}
.form-group > .property {
  display: block;
  color: var(--gray600);
}

.form-group > .property:hover i.fa-copy {
  display: inline-block !important;
}

.form-group > .property i.fa-copy {
  width: 0;
  display: none;
  margin-left: 1rem;
  cursor: pointer;
}

.form-group > .property i.fa-copy:hover {
  color: var(--gray700) !important;
}

.form-group > .property.unchanged {
  color: var(--red600);
  text-decoration: line-through;
}

.form-control-sm {
  height: 34px;
  font-size: 12px !important;
}

/* dropdown menus. */

.dropdown-menu {
  min-width: 280px;
  color: var(--gray800);
  padding: 0.75rem 0;
  font-size: 14px !important;
  border: none;
  border-radius: 0.25rem;
  box-shadow: rgba(82, 63, 105, 0.15) 0px 0px 50px 0px;
  user-select: none;
}

.dropdown-menu .dropdown-item {
  display: block;
  padding: 0 1rem;
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--900);
  text-decoration: none;
  background-color: var(--gray200);
}

.dropdown-item:hover {
  background-color: var(--gray200);
  border-radius: 0;
  cursor: pointer;
}

.dropdown-menu .dropdown-heading {
  display: block;
  font-size: 12px;
  font-weight: 600;
  color: var(--gray600);
  padding: 0.5rem 1rem;
  text-transform: uppercase;
}

.dropdown-menu .dropdown-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
}

.dropdown-menu .dropdown-button:hover {
  background-color: var(--gray200);
  transition: all 0.2s ease;
}

.dropdown-toggle::after {
  display:none;
}


/* custom radio switch. */

.custom-switch .custom-control-label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-switch .custom-control-label::before {
  background: rgba(0, 0, 0, 0.12);
  border: none;
  top: 0.2rem;
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: var(--primary);
  background-color: var(--primary);
}

.custom-switch .custom-control-label::after {
  border-color: var(--white);
  background-color: var(--white);
  top: calc(0.2rem + 2px);
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  border-color: var(--gray200);
  background-color: var(--gray200);
}

.custom-control-input[disabled]~.custom-control-label,
.custom-control-input:disabled~.custom-control-label {
  color: rgba(60, 66, 87, 0.5);
}

.custom-control-input[disabled]~.custom-control-label::before,
.custom-control-input:disabled~.custom-control-label::before {
  background-color: var(--gray100);
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(255, 255, 255, 0.5);
}

/* custom radio button element. */

.custom-check {
  position: relative;
  display: flex;
  align-items: center;
}

.custom-check .custom-check-input {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  background-color: var(--white);
  border: 1px solid var(--gray300);
  border-radius: 50%;
  transition: border 0.25s, background-color 0.25s;
  appearance: none;
}

.custom-check .custom-check-input:disabled {
  background-color: var(--gray100);
  user-select: none;
}

.custom-check .custom-check-input:checked {
  background-color: var(--black);
  border: 1px solid var(--black);
}

.custom-check .custom-check-input:focus {
  outline: 0;
  box-shadow: rgb(58 151 212 / 34%) 0px 0px 0px 0.2rem;
}

.custom-check .custom-check-input::before {
  content: "";
  height: 6px;
  width: 6px;
  border-radius: 8px;
  background-color: transparent;
  z-index: 1;
  transition: background-color 0.25s;
}

.custom-check .custom-check-input:checked::before {
  background-color: var(--white);
}

.custom-check .custom-check-padder {
  position: absolute;
  height: 22px;
  width: 22px;
  margin-bottom: 0;
  margin-left: -4px;
}

.custom-check .custom-check-label {
  position: relative;
  margin-left: 0.5rem;
  margin-bottom: 0;
  user-select: none;
}

/* pagination. */

.pagination-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-items {
  margin-right: 0.75rem;
}

.pagination-button {
  flex-shrink: 0;
  margin: 0 0.25rem;
}

.pagination-button+.pagination-button {
  margin-right: 0 !important;
}

/* tooltip. */

.tooltip-inner {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  min-width: 120px !important;
  max-width: 400px !important;
  color: var(--white);
  background: #131313;
}

.form-row+.form-row {
  margin-top: 1rem;
}
