.card {
  position: relative;
  border: 1px solid var(--gray200);
  border-radius: 6px;
  background: var(--white);
  box-shadow: 0 1px 4px 0 var(--gray300);
  -webkit-box-shadow: 0 1px 4px 0 var(--gray300);
}

.card .card-body {
  padding: 24px;
  background: var(--white);
  border-radius: 6px;
}

.card .card-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 24px;
  height: 56px;
  background-color: var(--white);
  border-bottom: 1px solid var(--gray300);
}
