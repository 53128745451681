.modal-open {
  overflow: scroll !important;
}

.modal-dialog {
  max-width: 1280px;
}

.modal-content {
  box-shadow: 0 24px 64px rgba(0, 0, 0, 0.20);
}

.modal-dialog.modal-sm {
  max-width: 484px !important;

  &.modal-sm-plus {
    max-width: 570px !important;
  }
}

.modal-dialog.modal-lg {
  max-width: 800px !important;
}

.modal-header {
  position: relative;
  align-items: center;
  justify-content: center !important;
  min-height: 65px;
  padding: 1rem 2rem;
  background: var(--white);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.modal-header .btn-ellipsis {
  position: absolute;
  right: 2rem;
}

.modal-subheader {
  padding: 2rem 2rem;
  background: var(--white);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.modal-content {
  box-sizing: border-box;
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.modal-body {
  padding: 2rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.modal-backdrop {
  color: var(--gray700);
}

// special modals classes

.edit-email-template-modal.modal {
  &.show {
    overflow: auto;
  }

  .modal-dialog {
    min-width: 1096px;
  }
}

.modal-dialog.common-modal-form-dialog {
  .modal-content {
    min-width: 450px;
  }
}

.asic-form-modal-window {
  overflow-x: auto !important;

  .asic-form-modal-dialog {
    min-width: 650px;
  }
}
