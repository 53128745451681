.autocomplete-container, .input-container, .date-range-picker-container, .select-container {

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;

    .label {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: var(--gray900);
    }

    .label-readonly {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: var(--gray700);
    }
  }
}

.autocomplete-container, .input-container {

  .input-wrapper {
    display: flex;
    align-items: center;
    position: relative;

    .input-icon {
      position: absolute;
      right: 10px;
      bottom: 14px;

      &.bulk {
        right: 34px;
      }
    }

    .ic-circle-check {
      background-color: var(--other-green);
    }

    .ic-close {
      background-color: var(--gray900);
    }

    &.error .input-icon {
      background-color: var(--other-red);
    }

    &.error .input, &.error:hover .input {
      border-color: var(--other-red);
      box-shadow: none;
      color: var(--other-red);
    }

    &.error:hover .input {
      background-color: var(--white);
    }

    &.error:active .input {
      border: 1px solid var(--other-red);
      box-shadow: 0 0 0 3px var(--other-red-light);
    }

    &.check .input-icon {
      background-color: var(--other-green);
    }

    &.check .input, &.check:hover .input {
      border-color: var(--other-green);
      box-shadow: none;
      color: var(--black);
    }

    &.check:hover .input {
      background-color: var(--other-green-light);
    }

    &.check:active .input {
      border: 1px solid var(--other-green);
      box-shadow: 0 0 0 3px var(--other-green-light);
    }

    &.warn .input-icon {
      background-color: var(--other-orange);
    }
  }
}
