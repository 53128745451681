/*.btn {
  height: 40px !important;
  font-size: 14px !important;
  line-height: 40px !important;
  font-weight: 500 !important;
  white-space: nowrap !important;
  padding: 0 1.5rem !important;
  border: none !important;
  border-radius: 0.25rem !important;
  outline: none !important;
  box-shadow: none !important;
  user-select: none;
}*/

.btn {
  box-sizing: border-box;
  height: 36px !important;
  font-size: 14px !important;
  line-height: 36px !important;
  font-weight: 500 !important;
  white-space: nowrap !important;
  padding: 0 0.75rem !important;
  border: 1px solid transparent !important;
  border-radius: 0.25rem !important;
  outline: none !important;
  box-shadow: none !important;
  user-select: none;

  .icon {
    background-color: var(--white);
  }
}

.btn:disabled {
  .icon {
    background-color: var(--gray500);
  }
}

.btn-secondary {
  .icon {
    background-color: var(--black);
  }
}

.btn.btn-icon i {
  margin-right: 0.5rem;
}

.btn:disabled,
a[disabled="disabled"] {
  opacity: 0.5 !important;
  cursor: default !important;
  pointer-events: none !important;
}

.btn-primary {
  color: var(--white) !important;
  border: 1px solid var(--primary) !important;
  background: var(--primary) !important;
}

.btn-primary:hover {
  background: var(--primary-dark) !important;
}

.btn-primary:disabled {
  color: var(--gray500) !important;
  background: var(--primary-light) !important;
}

.btn-primary:active {
  border: 1px solid var(--gray900) !important;
  background: var(--primary-dark) !important;
}

.btn-primary:focus {
  border: 1px solid var(--gray900) !important;
}

.btn-secondary {
  color: var(--gray900) !important;
  background: var(--white) !important;
  border: 1px solid var(--gray400) !important;
}

.btn-secondary:hover {
  background: var(--gray100) !important;
}

.btn-secondary:disabled {
  color: var(--gray500) !important;
  background: var(--gray200) !important;
  border: 1px solid transparent !important;
}

.btn-secondary:active {
  background: var(--gray300) !important;
  border: 1px solid var(--gray600) !important;
}

.btn-secondary:focus {
  border: 1px solid var(--gray600) !important;
}

.btn-gray {
  background: var(--gray300) !important;
  border: 1px solid var(--gray600) !important;
}

.btn-resolve {
  color: var(--white) !important;
  background:#FF6B00 !important;
  border: 1px solid #FF6B00 !important;
}

.btn-default {
  color: var(--gray700) !important;
  background: var(--default) !important;
}

.btn-default:hover {
  background: var(--default-hover) !important;
}

.btn-block {
  width: 100% !important;
}

.btn:not(.btn-block)+.btn:not(.btn-block) {
  margin-left: 1rem;
}
