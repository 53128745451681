@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-quartz.css';

.actions-button-cell {
  overflow: visible;
}

.ag-row {
  z-index: 0;
}

.ag-row.ag-row-focus {
  z-index: 1;
}

.ag-center-cols-viewport {
  overflow: visible;
}

.ag-ltr .ag-cell {
  user-select: text;
  cursor: text;
}

.ag-ltr .ag-cell.hover-underline {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
