h1.h1-heading,
h2.h2-heading {
  font-weight: 600;
  line-height: 1.6;
  color: var(--gray800);
}

h1.h1-heading {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
  color: var(--gray800);
  margin-bottom: 1.5rem;
}

h2.h2-heading {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.6;
  color: var(--gray800);
  margin-bottom: 1rem;
}

h3.h3-heading {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.6;
  color: var(--gray800);
  margin-bottom: 1rem;
}

p.sub-heading {
  font-size: 12px;
  line-height: 16px;
  color: var(--gray500);
}

.font-size-16 {
  font-size: 16px;
}

a {
  color: var(--blue800);
  font-weight: 400 !important;
  cursor: pointer !important;
  outline: none !important;
  text-decoration: none !important;
}

a:not(.btn):hover {
  color: var(--gray900);
  text-decoration: none;
}

.text-gray50 {
  color: var(--gray50) !important;
}

.text-gray100 {
  color: var(--gray100) !important;
}

.text-gray200 {
  color: var(--gray200) !important;
}

.text-gray300 {
  color: var(--gray300) !important;
}

.text-gray400 {
  color: var(--gray400) !important;
}

.text-gray500 {
  color: var(--gray500) !important;
}

.text-gray600 {
  color: var(--gray600) !important;
}

.text-gray700 {
  color: var(--gray700) !important;
}

.text-gray800 {
  color: var(--gray800) !important;
}

.text-gray900 {
  color: var(--gray900) !important;
}

.text-red700 {
  color: var(--red700) !important;
}

.form-number {
  font-size: 12px;
  color: var(--gray600);
}
