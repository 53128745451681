/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.26, autoprefixer: v9.7.3) */

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

body {
  height: 100%;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  background-color: var(--gray50);
  color: var(--gray800);
  overflow-y: auto;
}

html {
  height: 100%;
  overflow: auto;
}
